import { AiFillHome, AiOutlineBook, AiFillMessage } from 'react-icons/ai';
import { HiOutlineUserGroup } from 'react-icons/hi';
// import { BiPencil } from "react-icons/bi";

export const links = [
	{
		title: 'Overview',
		links: [
			{
				name: 'overview',
				icon: <AiFillHome />,
			},
		],
	},

	{
		title: 'Pages',
		links: [
			{
				name: 'total-votes',
				icon: <HiOutlineUserGroup />,
			},
			{
				name: 'candidates',
				icon: <HiOutlineUserGroup />,
			},
			{
				name: 'categories',
				icon: <AiOutlineBook />,
			},
			{
				name: 'create-categories',
				icon: <AiOutlineBook />,
			},
			{
				name: 'create-sub-categories',
				icon: <AiOutlineBook />,
			},
			{
				name: 'reservations',
				icon: <AiOutlineBook />,
			},
			{
				name: 'set-event-info',
				icon: <AiFillMessage />,
			},
			{
				name: 'set-event-speaker',
				icon: <AiFillMessage />,
			},
			{
				name: 'event-speakers',
				icon: <AiFillMessage />,
			},
		],
	},
];
